<template>
  <section :class="`${mobile ? 'uk-margin-medium-top' : 'section-background uk-margin-large-top'}`">
    <div class="uk-container">
      <div class="uk-grid-collapse uk-child-width-expand@s" uk-grid>
        <img-area :class="`${mobile ? 'uk-flex uk-flex-center' : 'uk-hidden@s'}`">
          <img src="./protectImg.png" alt="" />
        </img-area>
        <text-area :class="`${!mobile && 'uk-margin-large-top'}`">
          <h1
            class="uk-text-tlt-2 uk-margin-medium-bottom uk-margin-mobile-text"
          >
            {{ text_tl }}
          </h1>
          <div>
            <h2 class="uk-text-tl2">
              {{ text_t2 }}
            </h2>
            <h2 class="uk-text-tl2 uk-margin-mobile">
              {{ text_t3 }}
            </h2>
          </div>
        </text-area>
        <img-area class="uk-visible@s content-end">
          <img src="./protectImg.png" alt="" />
        </img-area>
      </div>
      <div
        class="uk-grid-collapse uk-child-width-expand@s uk-margin-large-bottom"
        uk-grid
      >
        <img-area :class="`${mobile && 'uk-flex uk-flex-center'}`">
          <img src="./protectImg2.png" alt="" />
        </img-area>
        <text-area class="uk-margin-xlarge-top">
          <h1 class="uk-text-tlt margin-mobile">{{ text_t4 }}</h1>
          <h2 class="uk-text-tl2 margin-mobile_2">
            <a
              class="redirect-text"
              target="_blank"
              href="https://privacyportal-br.onetrust.com/webform/83561353-2ef7-42f6-9e3e-1ff4d10a4da8/47bd0183-1f0f-4a6a-9991-24ffb89f72b8"
              >{{ text_t5 }}</a
            >{{ text_t6 }}
          </h2>
          <a href="https://grupobamaq.com.br/lgpd/lgpd.pdf" target="_blank">
            <button class="button-header uk-margin-top" >
              {{ text_button }}
              <img src="./icone.svg" class="icon_button" />
            </button>
          </a>
        </text-area>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProtectData',
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      text_tl: 'Como protegemos seus dados?',
      text_t2:
        'Adotamos medidas técnicas e organizacionais apropriadas para proteger os dados pessoais que coletamos contra perda ou qualquer forma de tratamento inadequado.',
      text_t3:
        'Contudo, apesar dessas medidas, nenhum site, aplicativo, transmissão de Internet, sistema de computador ou conexão sem fio, é completamente seguro.',
      text_t4: 'Ficou com dúvidas sobre como seus dados pessoais são tratados, ou sobre questões de privacidade e proteção de dados pessoais, ou se deseja exercer algum de seus direitos como titular de dados pessoais?',
      text_t5: 'Clique aqui',
      text_t6: ' e entre em contato com o nosso Encarregado de Dados Pessoais.',
      text_button: 'Faça o download do Aviso de Privacidade e Proteção de Dados',
      textList: ['Disponibilizar os serviços e produtos solicitados;'],
    }
  },
}
</script>

<style scoped>
.button-header {
  padding: 16px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #F9154F;
  border: none;
  color: white;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  box-shadow: 0px 1px 6px 0px #00000040;
}
.icon_button {
  margin-left: 5px;
  margin-top: -5px;
}
.background-color-section {
  background-color: rgba(142, 47, 167, 0.35);
}
.uk-text-tlt {
  color: #631079;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.08px;
}
.uk-text-tlt-2 {
  color: #631079;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.08px;
}
.redirect-text {
  color: #631079;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.048px;
  text-decoration-line: underline;
}
.uk-text-tl2 {
  color: #631079;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.048px;
}
.uk-text-tl3 {
  color: #631079;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.048px;
}
.content-end {
  display: flex;
  justify-content: flex-end;
}
.uk-margin-large-top {
  margin-top: 90px !important;
}
@media only screen and (max-width: 768px) {
  .uk-text-tlt-2 {
    font-size: 20px;
    margin-top: 40px;
    margin-left: 0 auto;
    text-align: center;
  }
  .uk-margin-mobile {
    margin-top: -10px;
  }

  .uk-margin-mobile-text {
    margin-bottom: 20px !important;
  }
  .uk-text-tlt {
    font-size: 20px;
    margin: 0 auto;
    text-align: left;
    line-height: 22px;
  }
  .redirect-text {
    font-size: 16px;
  }
  .uk-text-tl2 {
    font-size: 16px;
    line-height: 22px;
  }
  .button-header {
    padding: 12px 48px;
    font-size: 12px;
    margin: 0;
    box-shadow: 0px 1px 6px 0px #00000040;
    padding: 12px;
    width: 100%;
  }
  .margin-mobile {
    margin-top: -40px;
  }
  .margin-mobile_2 {
    margin-top: 10px;
  }
}
</style>
