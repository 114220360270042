<template>
  <HeaderPage :mobile="screenWidth < 768" />
  <PrivacyData :mobile="screenWidth < 768" />
  <OurBrands />
  <Brands :screenWidth="screenWidth" />
  <informationCollected />
  <UsageSharing :mobile="screenWidth < 768" />
  <ProtectData :mobile="screenWidth < 768" />
  <FooterPage :mobile="screenWidth < 768" />
</template>

<script>
import HeaderPage from './components/HeaderPage/HeaderPage.vue'
import PrivacyData from './components/PrivacyData/PrivacyData.vue'
import OurBrands from './components/OurBrands/OurBrands.vue'
import Brands from './components/Brands/Brands.vue'
import informationCollected from './components/informationCollected/informationCollected.vue'
import UsageSharing from './components/UsageSharing/UsageSharing.vue'
import ProtectData from './components/ProtectData/ProtectData.vue'
import FooterPage from './components/FooterPage/FooterPage.vue'

export default {
  name: 'App',
  components: {
    HeaderPage,
    PrivacyData,
    OurBrands,
    Brands,
    informationCollected,
    UsageSharing,
    ProtectData,
    FooterPage,
  },
  data: () => ({
    screenWidth: window.innerWidth,
  }),
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    document.title = 'Grupo Bamaq | Privacidade e Dados'
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }
}
</script>

<style></style>
