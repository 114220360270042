<template>
  <section>
    <div class="uk-container uk-container-large">
      <div class="uk-margin-large-top uk-margin-medium-bottom uk-text-center">
        <swiper
          :style="{
            '--swiper-pagination-color': '#f80c54',
            '--swiper-pagination-bullet-inactive-color': '#F2C8DD',
            '--swiper-pagination-bullet-inactive-opacity': '.5',
            '--swiper-pagination-bullet-size': '10px',
          }"
          class="swiper-container"
          :autoplay="{
            delay: 1500,
            disableOnInteraction: false,
          }"
          :pagination="{
            clickable: true
          }"
          :slides-per-view="screenWidth >= 768 ? 4 : 2"
          :space-between="30"
          :modules="modules"
        >
          <swiper-slide
            class="swiper-slide uk-flex uk-flex-center uk-flex-middle"
            v-for="(image, index) in imagePaths"
            :key="index"
          >
            <img :src="image" class="uk-align-center" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import bamaq_maquinas from './bamaq-maquinas.svg'
import bamaq_caminhoes from './bamaq-caminhoes.svg'
import bamaq_consorcio from './bamaq-consorcio.svg'
import bamaq_auto from './bamaq-auto.svg'
import bamaq_digital from './bamaq-digital.svg'
import bamaq_gwm from './bamaq-gwm.svg'
import bamaq_capital from './bamaq_capital.svg'
import bamaq_grupo from './bamaq_grupo.svg'
import koneq from './koneq.svg'
import delta from './delta.svg'
import cgf from './cgf.svg'
import collecc from './collecc.svg'

import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import "swiper/css/navigation";

export default {
  name: 'OurBrands',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    screenWidth: Number,
  },
  data() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      imagePaths: [
        bamaq_auto,
        bamaq_maquinas,
        bamaq_caminhoes,
        bamaq_consorcio,
        bamaq_capital,
        bamaq_grupo,
        bamaq_digital,
        bamaq_gwm,
        cgf,
        collecc,
        delta,
        koneq,
      ],
    }
  },
}
</script>

<style scoped>
</style>
