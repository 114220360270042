<template>
  <section>
    <div class="uk-container">
      <div class="uk-margin-large-top uk-margin-large-bottom">
        <h1 class="uk-text-title uk-text-center" id="help">{{ title }}</h1>
        <h1 class="uk-text-ft uk-margin-bottom uk-margin-mobile">
          {{ text_one }}
        </h1>
        <ul>
          <li class="uk-text-ft">{{ subTx }}</li>
          <li class="uk-text-ft">{{ subT2 }}</li>
          <li class="uk-text-ft">{{ subT3 }}</li>
        </ul>

        <div class="uk-margin-large-top">
          <div class="uk-card-body-ft">
            <p class="text-card-2">
              Também podemos pedir a sua autorização para a coleta de dados
              pessoais não descritos nessa Política de Privacidade e Dados, para
              que você possa, entre outras finalidades, participar de campanhas
              comerciais, salas de bate-papo e adquirir produtos e serviços em
              nosso site e aplicativo de vendas.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'informationCollected',
  data() {
    return {
      title: 'Quais informações são coletadas?',
      text_one:
        'Coletamos e armazenamos dados com o objetivo de oferecer a melhor experiência aos nossos visitantes on-line, incluindo:',
      subTx: 'Dados que você fornece diretamente para nós;',
      subT2:
        'Dados coletados sobre sua utilização de nossos serviços e produtos;',
      subT3: 'Dados que obtemos de fontes terceiras.',
    }
  },
}
</script>

<style scoped>
.text-card-2 {
  color: #631079;
  font-family: 'Work Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.048px;
  padding: 10px;
  margin: 0;
}
.uk-card-body-ft {
  background: rgba(142, 47, 167, 0.35);
  padding: 10px;
  border-radius: 10px;
}

.uk-text-card {
  color: #631079;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.048px;
  padding: 10px;
  height: 50px;
  font-family: 'Work Sans', sans-serif;
}
.uk-text-title {
  color: #631079;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.08px;
  font-family: 'Work Sans', sans-serif;
}
.uk-margin-ft {
  margin-top: -20px;
}
.uk-text-ft {
  color: #631079;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.048px;
  font-family: 'Work Sans', sans-serif;
}
@media only screen and (max-width: 700px) {
  .uk-text-title {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .uk-text-ft {
    font-size: 14px;
    line-height: 22px;
  }
  .uk-margin-mobile {
    margin-top: -15px;
  }
  .uk-text-card {
    font-size: 14px;
  }
  .uk-size-card-mobile {
    height: 200px;
  }
  .text-card-2 {
    font-size: 14px;
  }
}
</style>
