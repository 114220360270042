<template>
  <section class="section-background">
    <!-- DESKTOP -->
    <div v-if="!mobile" class="uk-container">
      <div
        class="uk-grid-collapse uk-child-width-expand@s uk-margin-large-top"
        uk-grid
      >
        <div>
          <div class="size-img">
            <img src="./logoBamaq.svg" alt="" />
          </div>
        </div>
        <div>
          <div class="text-title">Dúvidas</div>
          <div>
            <a
              href="https://ajuda.bamaqconsorcio.com.br/"
              target="_blank"
              class="sub-text"
              >Central de ajuda</a
            >
          </div>
          <div class="text-title uk-margin-medium-top">Redes Sociais</div>
          <li>
            <div class="el-content uk-panel uk-margin-small-top">
              <a
                class="fab fa-instagram"
                target="_blank"
                href="https://www.instagram.com/grupobamaq"
                style="font-size: 30px"
              />
              <a
                class="fab fa-linkedin-in uk-margin-left"
                target="_blank"
                href="https://www.linkedin.com/company/grupo-bamaq/"
                style="font-size: 30px"
              />
              <a
                class="fab fa-youtube uk-margin-left"
                target="_blank"
                href="https://www.youtube.com/grupobamaq"
                style="font-size: 30px"
              />
              <a
                class="fab fa-tiktok uk-margin-left"
                target="_blank"
                href="https://www.tiktok.com/@bamaqconsorcio"
                style="font-size: 30px"
              />
            </div>
          </li>
        </div>
        <div>
          <div class="text-title">Relacionamento</div>
          <div>
            <a
              href="https://bamaqdigital.com.br/lgpd"
              target="_blank"
              class="sub-text"
              >LGPD</a
            >
          </div>
          <div>
            <a
              href="https://strorbbits.blob.core.windows.net/orbbtis-utils/Compliance.pdf"
              target="_blank"
              class="sub-text"
              >Compliance</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.canaldedenuncias.com.br/grupobamaq/"
              class="sub-text"
              >Denuncie</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="https://privacyportal-br.onetrust.com/webform/83561353-2ef7-42f6-9e3e-1ff4d10a4da8/47bd0183-1f0f-4a6a-9991-24ffb89f72b8"
              class="sub-text"
              >Solicitações</a
            >
          </div>
        </div>
        <div>
          <div class="text-title">Faça parte do nosso time</div>
          <div>
            <a
              href="https://grupobamaq.gupy.io/"
              target="_blank"
              class="sub-text uk-margin-top"
              >Trabalhe conosco</a
            >
          </div>
          <div class="text-title uk-margin-medium-top">
            Assessoria de imprensa
          </div>
          <div class="sub-text">imprensabamaq@agenciafr.com.br</div>
        </div>
      </div>
      <div class="uk-margin-large-top">
        <div class="center-container">
          <hr class="line" />
        </div>
        <h1 class="text-footer uk-text-center">
          © Copyright 2024 - Grupo Bamaq - Todos os direitos reservados
        </h1>
      </div>
    </div>

    <!-- MOBILE -->
    <div v-else class="uk-container">
      <div
        class="uk-grid-collapse uk-child-width-expand@s uk-margin-large-top"
        uk-grid
      >
        <div class="img-bamaq-mobile uk-flex uk-flex-center uk-width-1-1 uk-grid-margin">
          <img src="./logoBamaq.svg" alt="" />
        </div>
        <div class="position-mobile uk-width-1-1 uk-flex-col uk-flex-middle uk-margin-medium-top">
          <div class="text-title">Dúvidas</div>
          <div>
            <a
              href="https://ajuda.bamaqconsorcio.com.br/"
              target="_blank"
              class="sub-text"
              >Central de ajuda</a
            >
          </div>
        </div>
        <div class="position-mobile uk-width-1-1 uk-flex-col uk-flex-middle uk-margin-medium-top">
          <div class="text-title">Redes Sociais</div>
          <li>
            <div class="el-content uk-panel uk-margin-small-top">
              <a
                class="fab fa-instagram"
                target="_blank"
                href="https://www.instagram.com/grupobamaq"
                style="font-size: 30px"
              />
              <a
                class="fab fa-linkedin-in uk-margin-left"
                target="_blank"
                href="https://www.linkedin.com/company/grupo-bamaq/"
                style="font-size: 30px"
              />
              <a
                class="fab fa-youtube uk-margin-left"
                target="_blank"
                href="https://www.youtube.com/grupobamaq"
                style="font-size: 30px"
              />
              <a
                class="fab fa-tiktok uk-margin-left"
                target="_blank"
                href="https://www.tiktok.com/@bamaqconsorcio"
                style="font-size: 30px"
              />
            </div>
          </li>
        </div>
        <div class="position-mobile uk-width-1-1 uk-flex-col uk-flex-middle uk-margin-medium-top">
          <div class="text-title">Relacionamento</div>
          <div>
            <a
              href="https://bamaqdigital.com.br/lgpd"
              target="_blank"
              class="sub-text"
              >LGPD</a
            >
          </div>
          <div>
            <a
              href="https://strorbbits.blob.core.windows.net/orbbtis-utils/Compliance.pdf"
              target="_blank"
              class="sub-text"
              >Compliance</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.canaldedenuncias.com.br/grupobamaq/"
              class="sub-text"
              >Denuncie</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="https://privacyportal-br.onetrust.com/webform/83561353-2ef7-42f6-9e3e-1ff4d10a4da8/47bd0183-1f0f-4a6a-9991-24ffb89f72b8"
              class="sub-text"
              >Solicitações</a
            >
          </div>
        </div>
        <div class="position-mobile uk-width-1-1 uk-flex-col uk-flex-middle uk-margin-medium-top">
          <div class="text-title">Faça parte do nosso time</div>
          <div>
            <a
              href="https://grupobamaq.gupy.io/"
              target="_blank"
              class="sub-text"
              >Trabalhe conosco</a
            >
          </div>
        </div>
        <div class="position-mobile uk-width-1-1 uk-flex-col uk-flex-middle uk-margin-medium-top">
          <div class="text-title">Acessoria de imprensa</div>
          <div class="sub-text">
            imprensabamaq@agenciafr.com.br
          </div>
        </div>
      </div>

      <div class="uk-margin-large-top">
        <div class="center-container">
          <hr class="line" />
        </div>
        <h1 class="text-footer uk-text-center">
          © Copyright 2024 - Grupo Bamaq - Todos os direitos reservados
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterPage',
  props: {
    mobile: Boolean,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.text-footer {
  margin: 20px 0 40px 0;
}
.uk-text-center {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  height: 1.838px;
  width: 1000px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  margin: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.section-background {
  background-color: #631079;
}
.size-img {
  width: 200px;
  height: 200px;
  margin-top: -20px;
}
.sub-text {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.text-title {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
@media only screen and (max-width: 768px) {
  .line {
    background: #fff;
    margin: 0;
    margin-top: 20px;
    width: 70%;
    height: 1px;
    border-radius: 4px 0px 0px 0px;
  }
  .text-title {
    font-size: 16px;
  }
  .sub-text {
    font-size: 12px;
  }
  .uk-text-navbar {
    font-size: 11px;
  }
  .img-bamaq-mobile {
    height: 116px;
    margin-left: -10px;
  }
  .position-mobile {
    margin: 0 auto;
    text-align: center;
  }
  .text-footer {
    margin: 10px 0 0 0;
    padding-bottom: 1.5rem;
    font-size: 10px;
  }
}
</style>
