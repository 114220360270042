<template>
  <section class="section-background uk-margin-top">
    <div class="uk-container">
      <div class="uk-grid-collapse uk-child-width-expand@s" uk-grid>
        <img-area>
          <img src="./sharingImg.png" alt="" />
        </img-area>
        <text-area>
          <h1 class="uk-text-tlt uk-margin-medium-bottom">{{ text_tl }}</h1>
          <div>
            <h1 class="uk-text-tl2">
              {{ text_t2 }}
            </h1>
          </div>

          <ul class="uk-text-tl3 uk-margin-medium-bottom uk-margin-mobile">
            <li v-for="(item, index) in textList" :key="index">
              {{ item }}
            </li>
          </ul>
        </text-area>
      </div>
    </div>
    <section class="background-color-section uk-margin-large-top">
      <div class="uk-container">
        <div class="uk-margin-large-top uk-margin-large-bottom">
          <h1 class="uk-text-tlt uk-text-center">
            Como seus dados podem ser compartilhados?
          </h1>
          <ul class="uk-text-tl3 uk-margin-medium-top">
            <li v-for="(item, index) in textListTwo" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'UsageSharing',
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      text_tl: 'Uso e compartilhamento',
      text_t2: 'Usamos os dados que coletamos para, entre outras coisas:',
      textList: [
        'Disponibilizar os serviços e produtos solicitados;',
        'Entender a forma como você usa o site para que possamos melhorar sua experiência;',
        'Compreender a forma como você utiliza nossos serviços e produtos para que possamos melhorá-los e desenvolver novos produtos e serviços;',
        'Fornecer conteúdo e publicidade personalizados;',
        'Avaliar e analisar o nosso mercado, clientes, produtos e serviços (incluindo o pedido da sua opinião sobre nossos produtos e serviços e a realização de pesquisas com clientes);',
        'Outras finalidades, com o seu consentimento.',
      ],
      textListTwo: [
        'Com as empresas do Grupo Bamaq;',
        'Com empresas que prestam serviços para a o Grupo Bamaq ou em nome dela;',
        'Por imposição da lei, quando somos obrigados a fazê-lo para proteger o grupo e seus usuários;',
        'Outras formas com o seu consentimento ou mediante sua solicitação.',
      ],
    }
  },
}
</script>

<style scoped>
.background-color-section {
  background-color: rgba(142, 47, 167, 0.35);
}
.uk-text-tlt {
  color: #631079;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.08px;
  align-items: end;
}
.uk-text-tl2 {
  color: #631079;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.048px;
}
.uk-text-tl3 {
  color: #631079;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.048px;
}

@media only screen and (max-width: 768px) {
  .uk-text-tlt {
    font-size: 20px;
    margin-top: 40px;
    margin-left: 0 auto;
    text-align: center;
  }
  .uk-text-tl2 {
    font-size: 14px;
    width: 300px;
  }
  .uk-text-tl3 {
    font-size: 14px;
  }
  .uk-margin-mobile {
    margin-top: -15px;
  }
}
</style>
