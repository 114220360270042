<template>
  <section class="section-background">
    <div class="uk-container">
      <div class="uk-grid-collapse uk-child-width-expand@s" uk-grid>
        <text-area>
          <div class="uk-margin-medium-top uk-text-center uk-hidden@s">
            <img :class="`${mobile ? 'uk-width-1-2 uk-margin-xlarge-bottom' : 'img_dario'}`" src="https://strorbbits.blob.core.windows.net/orbbtis-utils/qbamaq.svg" />
          </div>
          <div :class="`${mobile ? 'container-mobile' : 'uk-margin-large-top'}`">
            <h1 class="uk-title">{{ title }}</h1>
            <h2 class="uk-subtitle">{{ subtitle }}</h2>
            <a href="https://strorbbits.blob.core.windows.net/orbbtis-utils/Aviso%20de%20Privacidade%20LGPD.pdf" target="_blank">
              <button class="button-header uk-margin-top">
                Faça o download do Aviso de Privacidade e Proteção de Dados
                <img src="./icone.svg" class="icon_button" />
              </button>
            </a>
          </div>
        </text-area>
        <img-area>
          <div style="height: 80%;"
            class="uk-text-right uk-margin-medium-top uk-margin-medium-bottom uk-visible@s"
          >
            <img style="height: 100%;" class="img_dario" src="https://strorbbits.blob.core.windows.net/orbbtis-utils/qbamaq.svg" />
          </div>
        </img-area>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyData',
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      title: 'Privacidade e Dados',
      subtitle:
        'Proteger e manter o sigilo dos seus dados é uma prioridade para nós. Estamos comprometidos em manter os mais altos padrões de privacidade e segurança dos dados de cada usuário, pessoa física e/ou jurídica, na utilização dos serviços e produtos disponíveis em nossos sites.',
    }
  },
}
</script>

<style scoped>
.icon_button {
  margin-left: 5px;
}
.section-background {
  background-color: #631079;
}
.uk-container {
  padding: 10px 0 50px 0;
}
.uk-title {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  font-family: 'Work Sans', sans-serif;
}
.uk-subtitle {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.048px;
  width: 650px;
  font-family: 'Work Sans', sans-serif;
}
.button-header {
  padding: 14px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #8c3ab6;
  border: none;
  color: white;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-size: 500;
  box-shadow: 0px 1px 6px 0px #00000040;
}
@media only screen and (max-width: 768px) {
  .container-mobile {
    padding: 0 15px;
  }
  .uk-title {
    font-size: 24px;
    margin: 0 auto;
    text-align: center;
  }
  .uk-subtitle {
    font-size: 16px;
    width: 100%;
  }
  .button-header {
    padding: 12px 20px;
    font-size: 12px;
    margin-bottom: 40px;
    width: 100%;
  }
}
</style>
