<template>
  <section>
    <div class="uk-container" id="brands">
      <div class="uk-margin-large-top uk-margin-large-bottom">
        <h1 class="uk-text-title">{{ text_one }}</h1>
        <h1 class="uk-text-brands uk-text-center uk-margin-large-top">
          {{ title }}
        </h1>
        <h1 class="uk-text-firstText">
          {{ text_two }}
          <strong class="uk-text-SubText">{{ text_three }}</strong>
        </h1>
        <h1 class="uk-text-firstText uk-margin-mobile">{{ title_four }}</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurBrands',
  data() {
    return {
      title: 'Nossas marcas',
      text_one:
        'O Grupo Bamaq será considerado a controladora e, por este motivo, nomeou os operadores de dados para tratar informações pessoais em seu nome e agir de acordo com os padrões trazidos pela LGPD para fornecer a você os nossos serviços e produtos.',
      text_two:
        'Nosso compromisso com a ética, integridade e segurança vai além da nossa cultura.',
      text_three: 'Faz parte do DNA do Grupo Bamaq.',
      title_four:
        'A política de Dados e Privacidade abrange todas as marcas do Grupo Bamaq, sendo elas:',
    }
  },
}
</script>

<style scoped>
.uk-text-title {
  color: #631079;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.048px;
  margin: 0 auto;
}
.uk-text-brands {
  color: #631079;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.08px;
  margin-bottom: 35px;
}
.uk-text-firstText {
  color: #631079;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.048px;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
}
.uk-text-SubText {
  margin-top: -10px;
  color: #631079;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.048px;
  font-family: 'Work Sans', sans-serif;
}
@media only screen and (max-width: 768px) {
  .uk-text-title {
    font-size: 14px;
    margin: 0 auto;
    text-align: left;
  }
  .uk-text-brands {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .uk-text-firstText {
    font-size: 14px;
  }
  .uk-text-SubText {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .uk-margin-mobile {
    margin-top: -10px;
  }
}
</style>
