<template>
  <div class="">
    <nav class="uk-navbar-container background-nav">
      <div :class="`${mobile ? 'uk-position-relative' : 'uk-container'}`">
        <div uk-navbar class="container-mobile">
          <div :class="`${mobile ? 'uk-flex' : 'uk-navbar-left'}`">
            <img src="./logo.svg" alt="" :style="`${mobile ? 'margin: 0' : 'margin-left: -12px'}`" />
          </div>
          <div v-if="!mobile" class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <li>
                <a class="uk-text-navbar" href="#brands" uk-scroll="offset: 100"
                  >Nossas marcas</a
                >
              </li>
              <li>
                <a class="uk-text-navbar" href="#help" uk-scroll="offset: 100"
                  >Ajuda</a
                >
              </li>
            </ul>
          </div>
          <div v-else>
            <div class="uk-navbar-right padding-mobile">
              <button
                class="uk-navbar-toggle"
                uk-navbar-toggle-icon
                @click="() => showMenuHandle()"
              ></button>
            </div>
            <div
              v-if="showMenu"
              class="navbar-dropdown uk-box-shadow uk-animation-toggle uk-animation-slide-top-small"
            >
              <ul class="uk-nav uk-navbar-dropdown-nav padding-mobile">
                <li>
                  <a class="uk-text-navbar" href="#brands" uk-scroll="offset: 100"
                    >Nossas marcas</a
                  >
                </li>
                <li>
                  <a class="uk-text-navbar" href="#help" uk-scroll="offset: 100"
                    >Ajuda</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    showMenuHandle() {
      this.showMenu = !this.showMenu;
    }
  },
}
</script>

<style scoped>
.background-nav {
  background-color: #fff;
}

.navbar-dropdown {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 99999999 !important;
  background-color: #fff;
}

.uk-text-navbar {
  color: var(--Main-Colors-Primary-Primary, #631079);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.028px;
  text-transform: uppercase;
  font-family: 'Work Sans', sans-serif;
}

.padding-mobile {
  padding: 0 15px;
}
@media only screen and (max-width: 768px) {
  .uk-text-navbar {
    font-size: 11px;
  }
  .container-mobile {
    display: flex;
    justify-content: space-between;
  }
}
</style>
